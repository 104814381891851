// import { useCallback, useEffect, useRef, useState } from "react";
import "react-slideshow-image/dist/styles.css";
// import { Slide } from "react-slideshow-image";
// import { cn } from "@/lib/utils";
import Typewriter from "typewriter-effect";
function HeroSection() {
  return (
    <div className="">
      <h1 className="sr-only">Make Your Digital Wedding Invitation Video Special.</h1>
      <div className="relative font-Jost hidden sm:block">
        <img
          src="/assets/home/hero-image.webp"
          alt="Hero Image"
          className="w-full lg:aspect-auto lg:h-[80vh] h-auto md:aspect-auto  object-cover"
        />
        <div className="absolute top-1/2 left-[20%] -translate-x-1/2 -translate-y-1/2">
          <h2 className="text-black lg:text-6xl md:text-4xl text-[1rem] font-bold">
            Digital Invitation <br /> that Impress
          </h2>
          <div className="lg:mt-4 mt-0">
            <Typewriter
              options={{
                cursorClassName: "typewriter-text",
                wrapperClassName: "typewriter-text",
                strings: ["Video Invites", "PDF Invites", "Website Invites"],
                autoStart: true,
                loop: true,
              }}
            />
          </div>
        </div>
      </div>
      <div className="block sm:hidden">
        <div className="relative font-Jost">
          <img
            src="/assets/home/hero-image.svg"
            alt="Hero Image"
            className="w-full h-max object-fill"
          />
          <div className="absolute top-[18%] left-1/2 w-full text-center  -translate-x-1/2 -translate-y-1/2">
            <h2 className="text-black text-4xl w-full font-bold">
              <span className="text-lightRed">Digital Invitation</span> <br /> that
              Impress
            </h2>
            <div className="mt-4">
              <Typewriter
                options={{
                  cursorClassName: "typewriter-text",
                  wrapperClassName: "typewriter-text",
                  strings: ["Video Invites", "PDF Invites", "Website Invites"],
                  autoStart: true,
                  loop: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;

// const properties = {
//   duration: 2000,
//   transitionDuration: 500,
//   infinite: true,
//   arrows: false,
// };

// const [index, setIndex] = useState(0);
// const slideRef = useRef(null);

// const handleIndicatorClick = useCallback((clickedIndex) => {
//   if (slideRef.current) {
//     slideRef.current.goTo(clickedIndex);
//     setIndex(clickedIndex);
//   }
// }, []);
// <Slide
//   {...properties}
//   onChange={(o,newIndex) => setIndex(newIndex)}
//   ref={slideRef}
// >
//   {heroImage.map((image, idx) => (
//     <div
//       className="each-slide aspect-[16/9] lg:aspect-auto lg:h-[90vh] h-auto w-full mt-3"
//       key={idx}
//     >
//       <img
//         src={image.src}
//         alt={`Slide ${idx + 1}`}
//         className="w-full h-full object-cover"
//       />
//     </div>
//   ))}
// </Slide>
// <div className="absolute bottom-5 left-1/2 -translate-x-1/2">
//   <div className="flex items-center gap-2">
//     {heroImage.map((_, i) => (
//       <div
//         key={i}
//         className={cn(
//           `w-3 h-3 rounded-full cursor-pointer`,
//           index === i ? "bg-white" : "bg-white/50"
//         )}
//         onClick={() => {
//           handleIndicatorClick(i);
//         }}
//       ></div>
//     ))}
//   </div>
// </div> 
